import { Store } from "@store";
import Head from "next/head";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getNewHomeDetails } from "src/mainModule/queries/main.queries";
import HomeSection from "../../src/mainModule/views/homeView";
import { isLoggedIn } from "@utils/user/userCheck";
import { setLanugage } from "@utils/api/axiosInstance";

export default function Home({ homeData, userData, lang }) {
  const { t } = useTranslation();
  const { dispatch } = useContext(Store);
  // const [cookies, setCookie] = useCookies([]);

  useEffect(() => {
    // const loadUserData = async () => {
    //   const userData = await getUserData(cookies);

    //   //console.log("userData 1", userData);

    //   dispatch({
    //     type: "SET_DATA",
    //     payload: { userData, lang: userData.language || LANGUAGE.ENGLISH },
    //   });
    // };

    // loadUserData();

    dispatch({
      type: "SET_DATA",
      payload: { userData, lang },
    });

    // sessionStorage.setItem("locale", router.locale);
    // setLanugage(router.locale);
  }, [lang, userData]);

  return (
    <>
      <Head>
        <title>{t("utsav-hindu-temple-online-puja-and-prasad")}</title>
        <meta
          name="keywords"
          content={t(
            "utsav-utsavapp-home-page-of-utsav-app-online-puja-booking-online-puja-online-darshan-hindi-devotional-puja-app-darshan-app-online-puja-app-free-online-puja-hinduism-sanatan-dharma-lord-shiva-lord-krishna-indian-heritage-virtual-festivals-virtual-puja-locate-various-temple-connect-with-community-temples-of-india-festivals-community-platform-download-utsav-app-community-events-india-events-bhagavad-gita-yoga-devotion-festivals-spirituality-divine-god-belief"
          )}
        />
        <meta
          name="description"
          content={t(
            "utsav-is-indias-best-online-puja-booking-app-offer-puja-at-shaktipeeth-jyotirlingas-and-famous-mandir-watch-puja-and-receive-prasad-at-your-home-from-temple"
          )}
        />

        <meta
          property="og:title"
          content={t("utsav-app-my-temple-and-festivals")}
        />
        <meta
          property="og:description"
          content={t(
            "utsav-is-indias-most-loved-puja-booking-app-offer-pujas-at-shaktipeeth-jyotirlingas-mandirs-of-kashi-vrindavan-ujjain-watch-puja-darshan-and-receive-holy-prasad-at-your-home-directly-from-temple"
          )}
        />
        <meta property="og:url" content="https://utsavapp.in/home" />
        <meta
          property="og:image"
          content="https://d3k1i85mml78tf.cloudfront.net/Feeds/1705393164851_post_image_2.jpg"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://utsavapp.in/home" />
      </Head>

      <HomeSection homeData={homeData} />
    </>
  );
}

export async function getServerSideProps({ res, req, locale }) {
  const homeData = await getNewHomeDetails(locale);
  let loggedIn = await isLoggedIn(req.headers.cookie, res);

  let userData = null;
  let lang = locale;
  // const { data: trackingData, err: trackingError } = await getTrackingStatusForUser({
  //   trackingID: registrationID,
  // });
  // console.log("data", data, err);

  if (loggedIn.value) {
    userData = loggedIn.data;
  }
  setLanugage(lang);
  return {
    props: {
      homeData,
      userData,
      lang,
    },
  };
}
